import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const env = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3005/graphql'
    : '/graphql';

const httpLink = createHttpLink({
    uri: env
})


const defaultOptions = {
    watchQuery: {
        //  fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    },
    mutate: {
        errorPolicy: 'all'
    }
}

export const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions
})

