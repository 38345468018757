import utils from '../../services/utils'
import { initialState } from '../store-config'

export const setGlobalSnippets = (store, snippets) => {
    let currSnippets = store.state.snippets
    store.setState({ snippets: { ...currSnippets, content: [...snippets] } })
}


export const updateGlobalSnippet = (store, data) => {
    let snippets = store.state.snippets.content;
    let index = snippets.findIndex(snippet => snippet.id === data.id);
    snippets.splice(index, 1, data);
}

export const setSnippetsCount = (store, count) => {
    let pageCount = utils.calcPagesCount(count, store.state.snippets.pageSize);
    let currSnippets = store.state.snippets
    store.setState({ snippets: { ...currSnippets, pageCount } })
}

export const setCurrPage = (store, currPage) => {
    let currSnippets = store.state.snippets;
    let content = [];
    store.setState({ snippets: { ...currSnippets, content, currPage } })
    console.log('currPage: ', currPage)
}

export const setNewQuery = (store, type, payload) => {
    let { family, branch } = store.state.snippets;
    switch (type) {
        case 'family': family = payload; break;
        case 'branch': branch = payload; break;
        default: throw new Error('unknown type!');
    }
    store.setState({
        snippets: { ...initialState.snippets, family, branch }
    })
}

export const setRequery = (store, family, branch) => {
    store.setState({
        snippets: { ...initialState.snippets, family, branch }
    })
}