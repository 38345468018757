import React, { useEffect } from 'react'

const Home = (props) => {
    useEffect(() => {
        props.history.push('/snippets')
    }, [props])
    
    return (
        <div></div>
    )
}

export default Home