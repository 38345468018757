import React from 'react'
import { Route, Switch } from 'react-router-dom'
import About from './pages/AboutPage'
import Home from './pages/HomePage'
import Settings from './pages/SettingsPage'
import Snippets from './pages/SnippetsPage'
import Users from './pages/UsersPage'

const routes = (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/about" component={About} />
    <Route path="/settings" component={Settings} />
    <Route path="/snippets" component={Snippets} />
    <Route path="/users" component={Users} />
  </Switch>
);

export default routes;
