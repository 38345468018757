import {initialState} from '../store-config'

export const login = (store, credentials) => {
    let { user, token } = credentials;
    store.setState({ currUser: user, token: token });
};

export const logout = (store) => {
    store.setState(initialState);
};
export const setCodeStyleIndex = (store, index) => {
    store.setState({ codeStyleIndex: index })
}