import React from 'react'

import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import TextField from '@material-ui/core/TextField'

import { FAMILIES, BRANCHES } from '../assets/common/data'

const { Base64 } = require('js-base64');

const useStyles = makeStyles((theme) => ({
    form: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        position: 'relative',
        '& .MuiTextField-root': {
            margin: 0,
            minWidth: '45ch'
        },
        '& h4': {
            margin: '0 10.5px 0 8px',
            '& span': {
                color: theme.palette.error.main
            }
        },
        '& h2': {
            marginTop: 0,
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
            position: 'absolute',
            paddingBottom: '1rem',
            backgroundColor: 'white',
            zIndex: 10,
            width: '100%'
        },
        '& select,options': {
            backgroundColor: 'white'
        },
    },
    formContent: {
        overflowY: "auto",
        display: "flex",
        flexFlow: "column",
        width: "100%",
        marginTop: '3rem'
    },
    // contentMargin: {
    //     marginTop: '3rem'
    // },
    editMode: {
        height: '100%',
        paddingTop: '1rem',
        overflowY: 'auto'
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            paddingTop: '0.5rem',
            marginTop: '0.5rem'
        },
        margin: "1rem 0 0 0"
    },
    buttons: {
        marginTop: 'auto',
        paddingTop: '1rem',
        alignSelf: 'flex-end',
    },
    buttonMarginRight: {
        marginRight: '0.5rem'
    },
    buttonMarginAuto: {
        marginRight: 'auto'
    },
}))

const SnippetForm = (props) => {

    const classes = useStyles();
    const { register, handleSubmit } = useForm();

    const getBranch = () => {
        return BRANCHES.reduce((acc, branch) => {
            let r = Object.values(branch)[0].find(subBranch => subBranch === props.snippet.branch);
            if (r) acc = r;
            return acc
        }, 'General')
    };
    const getFamily = () => {
        return FAMILIES.find((family) => (family === props.snippet.family))
    };

    const cancel = (e) => {
        e.preventDefault();
        props.cbCancel();
    }

    const onSubmit = (data, e) => {
        e.target.reset();
        console.log('Submitted!');
        console.log(data);
        console.log('Curr User: ', props.currUser);
        console.log('Post Id: ', props.snippet.id)
        props.cbUpdateSnippet(data);
    }

    return (
        <div className={classes.editMode}>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h2>{props.type} Snippet</h2>
                <div className={classes.formContent}>
                    <div className={classes.section}>
                        <h4>Title <span>*</span></h4>
                        <TextField
                            inputRef={register}
                            variant="outlined"
                            color="secondary"
                            required
                            fullWidth
                            name="title"
                            type="text"
                            autoFocus
                            size="small"
                            defaultValue={props.snippet.title}
                        />
                    </div>
                    <div className={classes.section}    >
                        <div >
                            <h4>Family</h4>
                            <select className="custom-select" name="family" ref={register} defaultValue={getFamily()}>
                                {FAMILIES.map((family, index) =>
                                    <option key={index} value={family}>{family}</option>
                                )}
                            </select>
                        </div>
                        <div   >
                            <h4>Branch</h4>
                            <select className="custom-select" name="branch" ref={register} defaultValue={getBranch()}>
                                {BRANCHES.map(branch =>
                                    Object.keys(branch).map((key, index) =>
                                        <optgroup key={index} label={key}>
                                            {branch[key].map((value, index) =>
                                                <option key={index} value={value}>{value}</option>
                                            )}
                                        </optgroup>
                                    )
                                )}
                            </select>
                        </div>
                    </div>
                    <div className={classes.section}   >
                        <h4>Content <span>*</span></h4>
                        <TextField
                            inputRef={register}
                            variant="outlined"
                            color="secondary"
                            required
                            fullWidth
                            name="content"
                            multiline
                            rowsmin={3}
                            spellCheck="false"
                            size="small"
                            defaultValue={Base64.decode(props.snippet.content)}
                        />
                    </div>
                </div>
                <ButtonGroup className={classes.buttons} variant="outlined" color="secondary">
                    <Button variant="contained" color="secondary" onClick={cancel} className={classes.buttonMarginRight}>
                        Cancel
                            </Button>
                    <Button variant="contained" color="secondary" type="submit">
                        Submit
                        </Button>
                </ButtonGroup>
            </form>
        </div>
    )
}

export default SnippetForm
