import React, { useState, useCallback } from 'react'

import useGlobal from "../store/store"

import ReactMarkdown from 'react-markdown'
import CodeBlock from '../components/CodeBlock'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

import Growl from './GrowlCmp'

import SnippetForm from './SnippetForm'

const { Base64 } = require('js-base64');

const useStyles = makeStyles(() => ({
    appBar: {
        marginBottom: '0.5rem'
    },
    readMode: {
        height: '100%',
        overflowY: 'auto'
    },
    readModeFooter: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "cemter"
    },
    buttonMarginRight: {
        marginRight: '0.5rem'
    },
    buttonMarginAuto: {
        marginRight: 'auto'
    },
}))



const SnippetDetails = (props) => {

    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();

    const [snippet, setSnippet] = useState(props.location.snippet);
    const [postMode, togglePostMode] = useState(true);

    const [toShow, setToShow] = useState(false);
    const [growlProps, setGrowlProps] = useState({});

    const callback = useCallback(() => { setToShow(false) }, []);

    const close = () => props.history.push('/snippets');

    const stop = (e) => e.stopPropagation();

    const cancel = useCallback(() => togglePostMode(!postMode), [postMode]);

    const toggle = () => togglePostMode(!postMode);

    const removeSnippet = async () => {
        try {
            await globalActions.removeSnippet(snippet.id);
        } catch (e) { console.log('Error: ', e); }
    }

    const updateSnippet = useCallback(async data => {
        try {
            let snippetToUpdate = {
                id: snippet.id,
                title: data.title,
                family: data.family,
                branch: data.branch,
                content: Base64.encode(data.content)
            };
            await globalActions.updateSnippet(snippetToUpdate);
            const { title, family, branch, content } = data;
            const updatedSnippet = {
                ...snippet,
                title: title,
                family: family,
                branch: branch,
                content: Base64.encode(content)
            }
            setSnippet(updatedSnippet);
            // globalActions.clearContent();
            // await globalActions.getSnippets({
            //     family: globalState.snippets.family,
            //     branch: globalState.snippets.branch
            // });
            globalActions.updateGlobalSnippet(updatedSnippet);
            togglePostMode(!postMode);
            activateGrowl('success', 'Snippet successfully updated', 5000)
        } catch (e) {
            console.log('Error: ', e);
            activateGrowl('error', e.message, 5000)
        }
    }, [globalActions, postMode, snippet])

    const activateGrowl = (severity, message, delay) => {
        setGrowlProps({ severity: severity, message: message, delay: delay });
        setToShow(true);
    }

    if (!props.location.snippet) props.history.push('/')
    return (
        <div onClick={close} className="snippet-details-wrapper">
            <div onClick={stop} className="snippet-details-container">
                {postMode && <AppBar position="static" variant="outlined" className={classes.appBar} >
                    <Toolbar>
                        <Typography variant="h6" >
                            {snippet.family} : {snippet.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                }
                {postMode && <div className={classes.readMode}>
                    <ReactMarkdown source={Base64.decode(snippet.content)} renderers={{ code: CodeBlock }} />
                </div>
                }
                {!postMode && <SnippetForm
                    snippet={snippet}
                    currUser={globalState.currUser}
                    cbCancel={cancel}
                    cbUpdateSnippet={updateSnippet}
                    type='Edit'
                />}
                {postMode && <div className="snippet-details-footer">
                    <div className={classes.readModeFooter}>
                        <Button variant="contained" color="primary" onClick={removeSnippet} className={classes.buttonMarginAuto}>Delete</Button>
                        <Button variant="contained" color="primary" onClick={toggle} className={classes.buttonMarginRight}>Edit</Button>
                        <Button variant="contained" color="primary" onClick={close}>Done</Button>
                    </div>
                </div>
                }
                {toShow && <Growl close={callback} input={growlProps} />}
            </div>
        </div>
    )
}

export default SnippetDetails