import * as client from '../../assets/apollo/clientService'

export const getCredentials = async (store, data) => {
    try {
        let res = await client.queryCredentials(data)
        if (res.message === 'Validated') {
            store.actions.login({
                user: res.user,
                token: res.token
            });
        } else throw new Error(res.message)
    } catch (error) { throw (error.message) }
}

export const getCount = async (store, data) => {
    try {
        let token = store.state.token; 
        let res = await client.queryCount(data,token);
        store.actions.setSnippetsCount(res);
        console.log('Count: ',res)
    } catch (error) { throw (error.message) }
}

export const getSnippets = async (store, searchBy) => {
    try {
        let token = store.state.token; 
        let limit = store.state.snippets.pageSize;
        let offset = (store.state.snippets.currPage - 1) * limit;
        let res = await client.querySnippets(searchBy,limit,offset,token);
        store.actions.setGlobalSnippets(res);
        console.log('Result: ', res);
    } catch (error) { throw (error.message) }
}

export const addSnippet = async (store, data) => {
    try {
        let token = store.state.token; 
        let res = await client.mutateAddSnippet(data,token)
        console.log('Result: ', res)
    } catch (error) { throw (error.message) }
}

export const removeSnippet = async (store, id) => {
    try {
        let token = store.state.token; 
        let res = await client.mutateRemoveSnippet(id,token)
        console.log('Result: ', res)
    } catch (error) { throw (error.message) }
}

export const updateSnippet = async (store, data) => {
    try {
        let token = store.state.token; 
        let res = await client.mutateUpdateSnippet(data,token)
        console.log('Result: ', res)
    } catch (error) { throw (error.message) }
}

// export const getUsers = async (store, data) => {
//     try {
//         let users = await queryUsers();
//         store.setState({ users: users });
//     } catch (error) {
//         return { generalError: error.message }
//     }
// }
