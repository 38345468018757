import React from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import useGlobal from '../store/store'

import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '8rem',
        height: '100%',
        padding: '0.5rem',
        backgroundColor: theme.palette.primary.main,
        '& h2': {
            textDecoration: 'underline',
            fontStyle: 'italic',
            color: theme.palette.primary.main
        }
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
    },
    group: {
        backgroundColor: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        borderStyle: 'solid'
    },
    button: {
        color: theme.palette.secondary.main
    },
    hr: {
        color: theme.palette.primary.main,
        border: '1px solid',
        margin: 0,
        padding: 0,
        width: '100%'
    },
    avatar: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main,
        marginBottom: '1rem'
    }
}));

const SidePanel = (props) => {

    const classes = useStyles();
    const [, globalActions] = useGlobal();

    const logout = () => {
        globalActions.logout();
        props.validate(false);
        if (window.location.pathname !== '/') props.history.push('/')
    }
    
    return (
        <div className={classes.root}>
            <div className={classes.links}>
                <div className={classes.group}>
                    <h2>Vault</h2>
                    <Avatar className={classes.avatar}>YD</Avatar>
                    <hr className={classes.hr} />
                    <Button fullWidth color="primary" onClick={() => props.history.push('/')}>Home</Button>
                </div>
                <div className={classes.group}>
                    <Button fullWidth color="primary" onClick={() => props.history.push('/users')}>Users</Button>
                    <hr className={classes.hr} />
                    <Button fullWidth color="primary" onClick={() => props.history.push('/settings')}>Settings</Button>
                    <hr className={classes.hr} />
                    <Button fullWidth color="primary" onClick={() => props.history.push('/about')}>About</Button>
                    <hr className={classes.hr} />
                    <Button fullWidth color="primary" onClick={logout}>Logout</Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SidePanel)