import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import useGlobal from "../store/store"
import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Growl from './GrowlCmp'
import { LOGO_URL } from '../assets/common/data'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        background: 'white'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    spinner: {
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    labelAsterisk: {
        color: theme.palette.error.main
    }
}))

const useProgressiveImage = src => {
    const [sourceLoaded, setSourceLoaded] = useState(null)

    useEffect(() => {
        const img = new Image()
        img.src = src
        img.onload = () => setSourceLoaded(src)
    }, [src])

    return sourceLoaded
}

const Login = (props) => {
    const classes = useStyles();
    const [, globalActions] = useGlobal();
    const [toShow, setToShow] = useState(false);
    const [growlProps, setGrowlProps] = useState({});
    // const loaded = useProgressiveImage(LOGO_URL);
    const callback = useCallback(() => { setToShow(false) }, []);
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.target.reset();
        getCredentials(data);
    };

    async function getCredentials(creds) {
        function generateError(message) {
            setGrowlProps({ severity: 'error', message: message, delay: 5000 });
            setToShow(true);
        }

        try {
            await globalActions.getCredentials(creds);
            props.validate(true);
        } catch (e) { generateError(e) }
    }

    if (window.location.pathname !== '/') props.history.push('/')
    // if (!loaded) return (<div className={classes.spinner}><CircularProgress size='8rem' /></div>)
    return (
        <Grid container component="main" className={classes.root}>
            {/* <Grid item xs={false} sm={4} md={7} className={classes.image} style={{ backgroundImage: `url(${loaded})` }} /> */}
            <Grid item xs={false} sm={4} md={7} className={classes.image} style={{ backgroundColor:'gray' }} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                        </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <TextField
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            InputLabelProps={{
                                classes: {
                                    asterisk: classes.labelAsterisk
                                }
                            }}
                            name="email"
                            autoComplete="email"
                            // autoFocus
                        />
                        <TextField
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            InputLabelProps={{
                                classes: {
                                    asterisk: classes.labelAsterisk
                                }
                            }}
                            type="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
            </Grid>
            {toShow && <Growl close={callback} input={growlProps} />}
        </Grid>
    )
}

export default withRouter(Login)