import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import * as st from "react-syntax-highlighter/dist/esm/styles/prism";
// import useGlobal from "../store/store";

const prismStyles = [
  st.atomDark,
  st.base16AteliersulphurpoolLight,
  st.cb,
  st.darcula,
  st.duotoneDark,
  st.duotoneEarth,
  st.duotoneForest,
  st.duotoneLight,
  st.duotoneSea,
  st.duotoneSpace,
  st.ghcolors,
  st.hopscotch,
  st.pojoaque,
  st.vs,
  st.xonokai,
  st.coy,
  st.dark,
  st.funky,
  st.okaidia,
  st.solarizedlight,
  st.tomorrow,
  st.twilight,
  st.prism
]

function CodeBlock(props) {
  const { language, value } = props;
  // const [globalState] = useGlobal();
  // var style = prismStyles[globalState.codeStyleIndex]
  var style = prismStyles[18]
  return (
    <div>
      <SyntaxHighlighter language={language} style={style}>
        {value}
      </SyntaxHighlighter>
    </div>
  );
}

export default CodeBlock;