import Angular from './images/Angular.jpg'
import CSS from './images/CSS.jpg'
import General from './images/General.jpg'
import Git from './images/Git.jpg'
import GraphQL from './images/GraphQL.jpg'
import HTML from './images/HTML.jpg'
import JavaScript from './images/JavaScript.jpg'
import Laravel from './images/Laravel.jpg'
import Mongo from './images/Mongo.jpg'
import MySQL from './images/MySQL.jpg'
import Node from './images/Node.jpg'
import PostreSQL from './images/PostgreSQL.jpg'
import React from './images/React.jpg'
import Salesforce from './images/Salesforce.jpg'
import Sass from './images/Sass.jpg'
import Vue from './images/Vue.jpg'


export const im = {
    Angular: Angular,
    CSS: CSS,
    General: General,
    Git: Git,
    GraphQL: GraphQL,
    JavaScript: JavaScript,
    HTML: HTML,
    Laravel: Laravel,
    MongoDB: Mongo,
    MySQL: MySQL,
    Node: Node,
    PostgreSQL: PostreSQL,
    React: React,
    Salesforce: Salesforce,
    Sass: Sass,
    Vue: Vue
}