import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        // h1: 'h2',
        // h2: 'h2',
        // h3: 'h2',
        // h4: 'h2',
        // h5: 'h2',
        // h6: 'h8',
        // subtitle1: 'h2',
        // subtitle2: 'h2',
        // body1: 'span',
        body2: 'span'
      }
    }
  },
  palette: {
    primary: {
      // main: '#556cd6',
      // contrastText: '#ffcc00'
      main: '#13447e',
      // contrastText: '#000000',
      light: '#508dc7',
      // contrastText: '#e4dbd4'
      contrastText: '#ffffff'
    },
    secondary: {
      // main: '#19857b',
      // main: '#50abdf',
      main: '#508dc7',
      // contrastText: '#e4dbd4'
      // contrastText: '#000000'
      contrastText: '#ffffff'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff'
    },
    text: {
      secondary: '#e4dbd4'
      // secondary: '#000',
      // disabled: '#000',
      // hint: '#000',
      // icon: '#000',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // overrides: {
  //   MuiButton: {
  //     raisedPrimary: {
  //       color: 'white'
  //     }
  //   }
  // }
  // spacing: factor => `${0.25 * factor}rem`, // (Bootstrap strategy)
});

export default theme;