import { client } from './clients';
import * as q from './queries'
import * as m from './mutations'

export const queryCredentials = async (data) => {
    let result = await client
        .query({
            query: q.CREDENTIALS,
            variables: { email: data.email, password: data.password },
        })
    return result.data.userCredentials
}

export const queryCount = async (data, token) => {
    let result = await client
        .query({
            query: q.COUNT,
            variables: { countBy: data },
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.count.count
}

export const queryUsers = async (token) => {
    let result = await client
        .query({
            query: q.USERS,
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.users
}

export const querySnippets = async (searchBy, limit, offset, token) => {
    let result = await client
        .query({
            query: q.SNIPPETS,
            variables: { searchBy, limit, offset },
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.snippets
}

export const mutateAddSnippet = async (data,token) => {
    let result = await client
        .mutate({
            mutation: m.ADD_SNIPPET,
            variables: { snippet: data },
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.addSnippet.message
}

export const mutateRemoveSnippet = async (id,token) => {
    let result = await client
        .mutate({
            mutation: m.REMOVE_SNIPPET,
            variables: { id: id },
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.removeSnippet.message
}

export const mutateUpdateSnippet = async (data,token) => {
    let result = await client
        .mutate({
            mutation: m.UPDATE_SNIPPET,
            variables: { snippet: data },
            context: {
                headers: {
                    token
                }
            }
        })
    return result.data.updateSnippet.message
}
