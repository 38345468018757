import gql from 'graphql-tag';

export const CREDENTIALS = gql`
query($email: String, $password: String){
  userCredentials(email: $email, password:$password) {
    user {
      id
      first
      last
      email
      role
      created_at
      updated_at
    },
    message,
    token
  }
}
`

export const COUNT = gql`
query($countBy: CountInput){
  count(countBy: $countBy){
    count
  }
}
`
export const USERS = gql`
query{
  users{
    id
    first
    last
    role
    created_at
    updated_at
    }
}
`

export const SNIPPETS = gql`
query ($searchBy: SnippetQueryInput, $limit: Int, $offset: Int){
  snippets(searchBy: $searchBy, limit: $limit, offset: $offset){
    id
    title
    family
    branch
    content
    creator {
      id
      first
      last
    }
    created_at
    updated_at
    }
}
`