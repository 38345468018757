import gql from 'graphql-tag'

export const ADD_SNIPPET = gql`
mutation ($snippet: NewSnippetInput){
  addSnippet(snippet: $snippet) {
    message
  }
}
`

export const REMOVE_SNIPPET = gql`
mutation ($id: Int){
  removeSnippet(id: $id) {
    message
  }
}
`

export const UPDATE_SNIPPET = gql`
mutation ($snippet: SnippetInput){
  updateSnippet(snippet: $snippet) {
    message
  }
}
`
