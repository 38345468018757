import React, { useEffect, useCallback, useReducer } from 'react'
import { Route, withRouter } from "react-router-dom"

import useGlobal from "../store/store"

import SnippetList from '../components/SnippetList'
import SnippetDetails from '../components/SnippetDetails'
import SnippetCreation from '../components/SnippetCreation'

import { FAMILIES, BRANCHES } from '../assets/common/data'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'

const FAMILIES_FILTER = ['All', ...FAMILIES];
const BRANCHES_FILTER = [{ All: ['All'] }, ...BRANCHES]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& select': {
            width: '10rem',
            margin: 0,
            backgroundColor: 'white'
        },
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.text.secondary,
        zIndex: '10'
    },
    selects: {
        display: 'flex'
    },
    rt: {
        marginBottom: '1.2rem',
        fontSize: '0.9rem',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    actions: {
        paddingRight: '0.5rem',
    },
    divider: {
        width: '100%',
        paddingRight: '0.5rem',
        '& hr': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            border: '2px solid',
        }
    },
    paginator: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    spinner: {
        height: '100vh',
        backgroundColor: theme.palette.text.secondary,
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))


const Snippets = (props) => {

    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();

    const initialState = {
        toCount: true,
        toQuery: false
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'toCount': return { ...state, toQuery: true, toCount: action.payload };
            case 'toQuery': return { ...state, toQuery: action.payload };
            default: throw new Error('unknown reducer type!');
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const newQuery = (type, payload) => {
        globalActions.setNewQuery(type, (payload === 'All') ? '' : payload);
        dispatch({ type: 'toCount', payload: true });
    }

    const getSnippets = useCallback(async () => {
        try {
            const family = globalState.snippets.family;
            const branch = globalState.snippets.branch;
            if (state.toCount) {
                const countBy = {};
                if (family) countBy.family = family;
                if (branch) countBy.branch = branch;
                await globalActions.getCount({
                    table: 'snippets', params: countBy
                });
                dispatch({ type: 'toCount', payload: false });
                return
            } else if (state.toQuery) {
                await globalActions.getSnippets({ family, branch });
                dispatch({ type: 'toQuery', payload: false });
            }
        } catch (e) { console.log(e.message) }
    }, [globalState.snippets.family, globalState.snippets.branch, globalActions, state])

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) getSnippets();
        return () => isSubscribed = false;
    }, [getSnippets]);

    const handlePageChange = (event, value) => {
        globalActions.setCurrPage(value);
        dispatch({ type: 'toQuery', payload: true });
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.selects}>
                    <div>
                        <ruby>
                            <select className="custom-select" name="family" value={globalState.snippets.family}
                                onChange={e => newQuery('family', e.target.value)}>
                                {FAMILIES_FILTER.map((family, index) =>
                                    <option key={index} value={family}>{family}</option>
                                )}
                            </select>
                            <rt className={classes.rt}>Family</rt>
                        </ruby>
                    </div>
                    <div>
                        <ruby>
                            <select className="custom-select" name="branch" value={globalState.snippets.branch}
                                onChange={e => newQuery('branch', e.target.value)}>
                                {BRANCHES_FILTER.map(branch =>
                                    Object.keys(branch).map((key, index) =>
                                        <optgroup key={index} label={key}>
                                            {branch[key].map((value, index) =>
                                                <option key={index} value={value}>{value}</option>
                                            )}
                                        </optgroup>
                                    )
                                )}
                            </select>
                            <rt className={classes.rt}>Branch</rt>
                        </ruby>
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddIcon />}
                        onClick={() => props.history.push('snippets/add')}
                    >
                        Add
                    </Button>
                </div>
                <div className={classes.divider}>
                    <hr />
                </div>
                {globalState.snippets.pageCount > 1 && globalState.snippets.content.length > 0 && <div className={classes.paginator}>
                    <Pagination count={globalState.snippets.pageCount} page={globalState.snippets.currPage} color="primary" onChange={handlePageChange} />
                    <div className={classes.divider}>
                        <hr />
                    </div>
                </div>
                }
            </div>
            {globalState.snippets.content.length > 0 && <div style={{ display: "flex", justifyContent: "center" }}>
                <SnippetList snippets={globalState.snippets.content} />
            </div>
            }
            {!globalState.snippets.content.length && state.toQuery && <div className={classes.spinner}><CircularProgress size='8rem' /></div>}
            {!globalState.snippets.content.length && !state.toQuery && <div>No Records Found</div>}
            <Route path={`${props.match.url}/details`} component={SnippetDetails} />
            <Route path={`${props.match.url}/add`} component={SnippetCreation} />
        </div>
    )
}

export default withRouter(Snippets)