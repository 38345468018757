import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

import { red, green } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: '12px',
        right: '12px',
        transition: 'transform .6s ease-in-out',
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: '1rem',
        display: 'flex',
        justifyContent: 'cemter',
        alignItems: 'center',
        zIndex: 1000000
    },
    span: {
        margin: '0 2rem 0 1rem'
    }
}))

const SeverityIcon = (severity) => {
    return (severity === 'error')
        ? <ErrorIcon style={{ color: red.A400 }} />
        : <CheckIcon style={{ color: green.A400 }} />
}

const useTimeout = (callback, delay) => {
    const savedCallback = useRef();
    savedCallback.current = callback;

    useEffect(() => {
        function cb() {
            savedCallback.current();
        }

        let id = setTimeout(cb, delay);
        return () => clearTimeout(id);
    }, [delay]);
}

const Growl = (props) => {
    const classes = useStyles();
    const [toShow, setToShow] = useState(true);

    function close() {
        setToShow(false);
        props.close()
    }

    useTimeout(() => {
        close()
    }, props.input.delay)

    if (!toShow) return null

    return (
        <div className={classes.root}>
            {SeverityIcon(props.input.severity)}
            <span className={classes.span}>{props.input.message}</span>
            <IconButton color="inherit" onClick={close}>
                <CloseIcon />
            </IconButton>
        </div >
    )
}

export default Growl