export const LOGO_URL = 'https://source.unsplash.com/featured/?computer-code'

export const CODE_STYLES = [
    { label: 'atomDark', value: 0 },
    { label: 'base16AteliersulphurpoolLight', value: 1 },
    { label: 'cb', value: 2 },
    { label: 'darcula', value: 3 },
    { label: 'duotoneDark', value: 4 },
    { label: 'duotoneEarth', value: 5 },
    { label: 'duotoneForest', value: 6 },
    { label: 'duotoneLight', value: 7 },
    { label: 'duotoneSea', value: 8 },
    { label: 'duotoneSpace', value: 9 },
    { label: 'ghcolors', value: 10 },
    { label: 'hopscotch', value: 11 },
    { label: 'pojoaque', value: 12 },
    { label: 'vs', value: 13 },
    { label: 'xonokai', value: 14 },
    { label: 'coy', value: 15 },
    { label: 'dark', value: 16 },
    { label: 'funky', value: 17 },
    { label: 'okaidia', value: 18 },
    { label: 'solarizedlight', value: 19 },
    { label: 'tomorrow', value: 20 },
    { label: 'twilight', value: 21 },
    { label: 'prism', value: 22 }
];

export const FAMILIES = [
    'General',
    'Angular',
    'CSS',
    'Git',
    'GraphQL',
    'HTML',
    'JavaScript', 
    'Laravel',
    'MongoDB',
    'MySQL',
    'Node',
    'PostgreSQL',
    'React',
    'Salesforce',
    'Sass',
    'Vue'
];

export const BRANCHES = [
   {Common: ['General','Backup','Boilerplate','Deployment','Library','Testing']},
   {Angular: ['AngularJS','Angular2']},
   {GraphQL: ['Apollo']},
   {JavaScript: ['TypeScript']},
   {React: ['Hooks', 'Redux']},
   {Salesforce: ['Apex','LWC','VisualForce','Triggers']},
   {Vue: ['Vue3']}
];
