import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { im } from '../assets/common/img'

import Moment from 'react-moment'

const SnippetList = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '15rem',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center'
        },
        details: {
            display: 'flex',
            justifyContent: 'center',
        },
        media: {
            height: '7rem',
            width: '7rem',
            marginTop: '1rem',
        },
        title: {
            display: "flex",
            width: "100%",
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            '& div': {
                fontSize: '1rem',
                color: 'white',
                flexWrap: 'wrap',
                minHeight: '3rem',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textAlign: 'center'
            }
        },
        content: {
            alignSelf: 'center',
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px',
            borderBottomColor: theme.palette.primary.main
        },
        date: {
            textAlign: 'center',
        },
        header: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.main,
        },
        grid: {
            width: 'fit-content',
            display: 'grid',
            gridGap: '10px',
            [theme.breakpoints.only('xl')]: {
                gridTemplateColumns: 'repeat(6,1fr)',
            },
            [theme.breakpoints.only('lg')]: {
                gridTemplateColumns: 'repeat(4,1fr)',
            },
            [theme.breakpoints.only('md')]: {
                gridTemplateColumns: 'repeat(3,1fr)',
            },
            [theme.breakpoints.only('sm')]: {
                gridTemplateColumns: 'repeat(2,1fr)',
            },
            [theme.breakpoints.only('xs')]: {
                gridTemplateColumns: 'repeat(1,1fr)',
            }
        }
    }));

    const SnippetPreview = props => {
        return (
            <React.Fragment>
                <Card className={classes.root}>
                    <CardContent className={classes.title}>
                        <Typography gutterBottom variant="body2" color="textSecondary" component="div">
                            {props.snippet.title}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        className={classes.media}
                        image={im[props.snippet.family]}
                    />
                    <CardContent className={classes.content}>
                        <Typography variant="body2" color="primary" component="p">
                            {props.snippet.family} - {props.snippet.branch}
                        </Typography>
                        <Typography variant="body2" color="secondary" component="p" className={classes.date}>
                            <Moment fromNow>{props.snippet.created_at}</Moment>
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" size="small" color="primary" onClick={() => props.callback(props.snippet)}>
                            View
                        </Button>
                    </CardActions>
                </Card>
            </React.Fragment>
        )
    }

    const classes = useStyles();
    const [snippets, setSnippets] = useState([]);
    useEffect(() => {
        setSnippets(props.snippets)
    }, [props]);

    const callback = useCallback((snippet) => {
        props.history.push({
            pathname: 'snippets/details',
            snippet: snippet
        })
    }, [props]);

    return (
        <div className={classes.grid}>
            {snippets.map((snippet, index) => (
                <SnippetPreview key={index} callback={callback} snippet={snippet} />
            ))}
        </div >
    )
}

export default withRouter(SnippetList)

