import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <Typography variant="body2" className={classes.root} color="textSecondary"  align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://vault.mitsukurina.com/" target="_blank" rel="noopener noreferrer"  >
                    vault.mitsukurina.com
            </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
        </Typography>
    );
}

export default Footer