import React, { useState, useCallback } from 'react'
import routes from './routes'

import { makeStyles } from '@material-ui/core/styles'

import Login from './components/LoginCmp'
import Footer from './components/FooterCmp'
import SidePanel from './components/SidePanel'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    flexBasis: '100%'
  },
  content: {
    backgroundColor: theme.palette.text.secondary,
    width: '100%',
    borderRadius: '1rem',
    margin: '0.5rem',
    padding: '0.5rem',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
    '& > div': {
      position: 'relative',
      height: '100%',
      '& > div': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }
    }
  },
  relative: {
    position: 'relative',
    height: '100%',
    display: 'flex'
  }
}))

const App = () => {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const callback = useCallback((flag) => { setIsAuthenticated(flag) }, []);

  return (
    <div className="App">
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {!isAuthenticated && <div className={classes.relative}>
            <Login validate={callback} />
          </div>}
          {isAuthenticated && <div className={classes.relative}>
            <SidePanel validate={callback} />
            <div className={classes.content} >
              <div>
                <div>
                  {routes}
                </div>
              </div>
            </div>
          </div>}
        </div>
        {isAuthenticated && <Footer />}
      </div>
    </div>
  )
}

export default App;
