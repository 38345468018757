import React from 'react'
// import UserList from '../components/UserList'

const Users = ()=> {
    return(
    //   <UserList/>
    <div>Users</div>
    )
}

export default Users