export const initialState = {
    currUser: {
        id: '',
        first: '',
        last: '',
        email: '',
        role: '',
        created_at: '',
        updated_at: ''
    }, 
    token: '',
    snippets: {
        pageSize: 15,
        count: '',
        currPage: 1,
        content: [],
        family: '',
        branch: ''
    }
};
