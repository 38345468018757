import React, { useState, useCallback } from 'react'
import useGlobal from "../store/store"

import Growl from './GrowlCmp'
import SnippetForm from './SnippetForm'

const { Base64 } = require('js-base64');

const snippet = {
    title: '',
    family: '',
    branch: '',
    content: ''
}

const SnippetCreation = (props) => {

    const [globalState, globalActions] = useGlobal();
    const [toShow, setToShow] = useState(false);
    const [growlProps, setGrowlProps] = useState({});

    const callback = useCallback(() => { setToShow(false) }, []);

    const close = () => props.history.push('/snippets');

    const stop = (e) => e.stopPropagation();

    const activateGrowl = (severity, message, delay) => {
        setGrowlProps({ severity: severity, message: message, delay: delay });
        setToShow(true);
    }

    const addSnippet = useCallback(async data => {
        try {
            let snippetToAdd = {
                title: data.title,
                family: data.family,
                branch: data.branch,
                content: Base64.encode(data.content),
                creator_id: globalState.currUser.id
            };
            await globalActions.addSnippet(snippetToAdd);
            let { family, branch } = snippetToAdd;
            const countBy = { family, branch };
            globalActions.setRequery(family,branch);
            activateGrowl('success', 'Snippet successfully added', 5000);
            await globalActions.getCount({
                table: 'snippets', params: countBy
            });
            await globalActions.getSnippets({ family, branch });
            props.history.push('/snippets');
        } catch (e) {
            console.log('Error: ', e);
            activateGrowl('error', e.message, 5000)
        }
    }, [globalState, globalActions,props])

    return (
        <div onClick={close} className="snippet-details-wrapper">
            <div onClick={stop} className="snippet-details-container">
                <SnippetForm
                    snippet={snippet}
                    cbCancel={close}
                    cbUpdateSnippet={addSnippet}
                    type='Add'
                />
                {toShow && <Growl close={callback} input={growlProps} />}
            </div>
        </div>
    )
}

export default SnippetCreation
