const calcPagesCount = (count, size) => {
    if (!count) return 0;
    if (count <= size) return 1;
    let remainder = (count % size) ? 1 : 0;
    return Math.trunc(count / size) + remainder;
}

const makeChunks = (inputArray, n) => {
    return new Array(Math.ceil(inputArray.length / n)).fill().map(_ => inputArray.splice(0, n))
}

export default {
    calcPagesCount,
    makeChunks
}